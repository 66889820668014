<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay no-wrap :show="showOverlay" />
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <b-img
          :src="heroleadsLogo"
          alt="Heroleads Logo"
        />
        <h2 class="brand-text text-primary ml-1 mt-2">
          HeroAIX
        </h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img :src="imageUrl" fluid alt="image" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Reset Password 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Your new password must be different from previously used passwords
          </b-card-text>
          <validation-observer ref="observer">
            <b-form class="auth-reset-password-form mt-2" method="POST" @submit.prevent="validationForm">
              <b-form-group
                label="New Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Confirm Password"
                label-for="password-confirmation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="confirm password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password-confirmation"
                      v-model="passwordConfirmation"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
              >
                Set New Password
              </b-button>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <b-link :to="{ name: 'heroai-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Back To Login
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BRow,
  BCol,
  BButton,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BImg,
  BInputGroup,
  BLink,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  password as passwordRule,
} from '@validations'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,
      passwordRule,

      password: '',
      passwordConfirmation: '',
      heroleadsLogo: require('@/assets/images/logo/heroleads-logo.png'),
      image: require('@/assets/images/pages/reset-password-v2.svg'),
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    imageUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.image = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.image
      }
      return this.image
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.$store.commit('heroAiAuthentications/doLogout')
    this.$ability.update(this.$store.getters['heroAiAuthentications/getPermissions'])
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async validationForm() {
      let alertResult

      const axiosConfig = AxiosConfig.getJsonWithoutToken()
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to save new password?' })

        if (alertResult.value) {
          this.showOverlay = true

          // Get reset token from email link
          const { token } = this.$route.query
          const body = {
            password: this.password,
            password_confirmation: this.passwordConfirmation,
          }

          try {
            await axios.post(`password/reset?token=${token}`, body, axiosConfig)

            alertResult = await this.$swal({ ...SweetAlert.success, text: 'Password successfully changed' })

            if (alertResult.value) {
              await this.$router.push({ name: 'heroai-login' })
            }
          } catch (error) {
            this.showOverlay = false

            await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
          }

          this.showOverlay = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
